// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { client } from "@/sanity/client";
import { json } from "@remix-run/node";
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from "@remix-run/react";
import { Suspense, createContext, lazy } from "react";
import { CATEGORIES_QUERY, GET_ARTICLE_POSTS, GET_CORPORATE_LOANS_POSTS, GET_LOANS_POSTS, GET_SERVICE_POSTS } from "@/sanity/queries";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import stylesheet from "@/tailwind.css";
import React from "react";
export const AppContext = createContext({
  companyId: null,
  setCompanyId: () => {},
  phone: null,
  setPhone: () => {},
  email: null,
  setEmail: () => {},
  errors: {},
  setErrors: () => {},
  company: null,
  setCompany: () => {}
});
export const links = () => [{
  rel: "stylesheet",
  href: stylesheet
}, {
  rel: "preconnect",
  href: "https://www.googletagmanager.com"
}];
const LiveVisualEditing = lazy(_c = () => import("@/components/LiveVisualEditing"));

// export const meta: MetaFunction = () => {
//   return { title: "New Remix App" };
// };
_c2 = LiveVisualEditing;
export const loader = async ({
  params,
  request
}) => {
  const categories = await client.fetch(CATEGORIES_QUERY, params);
  const [articleItems, serviceItems, loansItems, corparationLoansItems] = await Promise.all([client.fetch(GET_ARTICLE_POSTS), client.fetch(GET_SERVICE_POSTS), client.fetch(GET_LOANS_POSTS), client.fetch(GET_CORPORATE_LOANS_POSTS)]);
  const footerData = {
    articles: articleItems,
    services: serviceItems,
    loans: loansItems,
    corporateLoans: corparationLoansItems
  };
  return json({
    footerData,
    categories,
    params,
    gtmId: process.env.GTM_ID,
    ENV: {
      SANITY_STUDIO_PROJECT_ID: process.env.SANITY_STUDIO_PROJECT_ID,
      SANITY_STUDIO_DATASET: process.env.SANITY_STUDIO_DATASET,
      SANITY_STUDIO_URL: process.env.SANITY_STUDIO_URL,
      SANITY_STUDIO_STEGA_ENABLED: process.env.SANITY_STUDIO_STEGA_ENABLED
    }
  });
};
export default function App() {
  _s();
  const {
    categories,
    params,
    ENV,
    gtmId,
    footerData
  } = useLoaderData();
  const [companyId, setCompanyId] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [phone, setPhone] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  const [company, setCompany] = React.useState(null);
  return <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <title>Bedriftsfinans</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <Suspense fallback={null}>
          <script dangerouslySetInnerHTML={{
          __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${gtmId}');
            `
        }} />

          <script dangerouslySetInnerHTML={{
          __html: `
              <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="e6ab35ef-bbd9-4c25-a12d-2327eb0778bc" data-blockingmode="auto" type="text/javascript"></script>
            `
        }} />

        </Suspense>
      </head>
      <body>
        <div className="min-h-80 w-full z-[-1] bg-bg-secondary absolute"></div>
        <div className="max-w-body-wh m-auto px-5">
          <Navbar data={categories} slug={params} />
          {/* <div className={"container mx-auto p-2 sm:px-6 lg:px-8 max-w-7xl"}> */}
          <AppContext.Provider value={{
          companyId,
          setCompanyId,
          phone,
          setPhone,
          email,
          setEmail,
          setErrors,
          errors,
          company,
          setCompany
        }}>

            <Outlet />
          </AppContext.Provider>
          <ScrollRestoration />
          <script dangerouslySetInnerHTML={{
          __html: `window.ENV = ${JSON.stringify(ENV)}`
        }} />

          {ENV.SANITY_STUDIO_STEGA_ENABLED ? <Suspense>
              <LiveVisualEditing />
            </Suspense> : null}
          <Scripts />
          <LiveReload />
          <noscript>
            <iframe src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`} height="0" width="0" style={{
            display: "none",
            visibility: "hidden"
          }}>
            </iframe>
          </noscript>
        </div>
        <Footer data={footerData} />
      </body>
    </html>;
}
_s(App, "a3zMD3A2w5LvUajgyy2hv5oGnTc=", false, function () {
  return [useLoaderData];
});
_c3 = App;
var _c, _c2, _c3;
$RefreshReg$(_c, "LiveVisualEditing$lazy");
$RefreshReg$(_c2, "LiveVisualEditing");
$RefreshReg$(_c3, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;